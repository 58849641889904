import React, { Component } from 'react';

export default class Services extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="services">
          <div className="row section-head">
            <div className="twelve columns">
              <h1>Our Awesome Services<span>.</span></h1>
              <hr />
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                voluptatem quia voluptas sit aspernatur aut odit aut fugit.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="twelve columns">
              <div className="service-list bgrid-third s-bgrid-half mob-bgrid-whole">
                <div className="bgrid">
                  <div className="icon-part">
                    <span className="icon-graph" />
                  </div>
                  <h3>Marketing.</h3>
                  <div className="service-content">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div> {/* /bgrid */}
                <div className="bgrid">
                  <div className="icon-part">
                    <i className="icon-film" />
                  </div>
                  <h3>Videography.</h3>
                  <div className="service-content">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventoveritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div> {/* /bgrid */}
                <div className="bgrid">
                  <div className="icon-part">
                    <i className="icon-camera" />
                  </div>
                  <h3>Photography.</h3>
                  <div className="service-content">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div> {/* /bgrid */}
                <div className="bgrid first">
                  <div className="icon-part">
                    <i className="icon-eye" />
                  </div>
                  <h3>Branding.</h3>
                  <div className="service-content">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div> {/* /bgrid */}
                <div className="bgrid">
                  <div className="icon-part">
                    <i className="icon-screen-desktop" />
                  </div>
                  <h3>Web Design.</h3>
                  <div className="service-content">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div> {/* /bgrid */}
                <div className="bgrid">
                  <div className="icon-part">
                    <i className="icon-eyeglasses" />
                  </div>
                  <h3>Web Development.</h3>
                  <div className="service-content">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div> {/* /bgrid */}
              </div> {/* /service-list */}
            </div> {/* /twelve */}
          </div> {/* /row */}
        </section>
      </React.Fragment>
    );
  }
}