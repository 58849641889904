import React, { Component } from 'react';

export default class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <header id="main-header">
          <div className="row header-inner">
            <div className="logo">
              <a className="smoothscroll" href="#hero">YWAV</a>
            </div>
            <nav id="nav-wrap">
              <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
                <span className="menu-text">Show Menu</span>
                <span className="menu-icon" />
              </a>
              <a className="mobile-btn" href="#" title="Hide navigation">
                <span className="menu-text">Hide Menu</span>
                <span className="menu-icon" />
              </a>
              <ul id="nav" className="nav">
                <li className="current"><a className="smoothscroll" href="#hero">Home.</a></li>
                <li><a className="smoothscroll" href="#portfolio">Works.</a></li>
                <li><a className="smoothscroll" href="#services">Services.</a></li>
                <li><a className="smoothscroll" href="#about">About.</a></li>
                <li><a className="smoothscroll" href="#journal">Blog.</a></li>
                <li><a className="smoothscroll" href="#contact">Contact.</a></li>
              </ul>
            </nav> {/* /nav-wrap */}
          </div> {/* /header-inner */}
        </header>
      </React.Fragment>
    );
  }
}