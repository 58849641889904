import React, { Component } from 'react';

export default class Blog extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="journal">
          <div className="row section-head">
            <div className="twelve columns">
              <h1>Our Latest Ramblings<span>.</span></h1>
              <hr />
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                voluptatem quia voluptas sit aspernatur aut odit aut fugit.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="twelve columns">
              <div id="blog-wrapper" className="bgrid-third s-bgrid-half mob-bgrid-whole group">
                <article className="bgrid">
                  <h5>September 2, 2014</h5>
                  <h3><a href="single.html">Sed ut perspiciatis unde omnis iste natus</a></h3>
                  <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor,
                    isi elit consequat ipsum, nec sagittis sem nibh id elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.
                  </p>
                </article>
                <article className="bgrid">
                  <h5>August 29, 2014</h5>
                  <h3><a href="single.html">Natus error sit voluptatem accusantium.</a></h3>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                  </p>
                </article>
                <article className="bgrid">
                  <h5>August 28, 2014</h5>
                  <h3><a href="single.html">Ut enim ad minim veniam quis nostrud</a></h3>
                  <p>
                    Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor,
                    isi elit consequat ipsum, nec sagittis sem nibh id elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.
                  </p>
                </article>
                <article className="bgrid first">
                  <h5>August 27, 2014</h5>
                  <h3><a href="single.html">Sed ut perspiciatis unde omnis iste natus</a></h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat.
                  </p>
                </article>
                <article className="bgrid">
                  <h5>August 25, 2014</h5>
                  <h3><a href="single.html">Rerum dignissimos alias eligendi laudantium quod.</a></h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum fuga distinctio natus ab sint
                    voluptate quos cumque assumenda ipsa cupiditate aperiam officiis recusandae nemo labore adipisci harum,
                    ea odio dolor.
                  </p>
                </article>
                <article className="bgrid">
                  <h5>August 23, 2014</h5>
                  <h3><a href="single.html">Sed ut perspiciatis unde omnis iste natus</a></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt cumque, aut nam at,
                    doloremque repellat blanditiis quo accusantium, rerum dignissimos alias eligendi, laudantium quod.
                    Autem nihil explicabo ipsa magnam porro.
                  </p>
                </article>
              </div> {/* /blog-wrapper */}
            </div> {/* /twelve */}
          </div> {/* /row */}
        </section>
      </React.Fragment>
    );
  }
}