import React, { Component } from 'react';

export default class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="contact">
          <div className="row section-head">
            <div className="twelve columns">
              <h1>Get In Touch With Us<span>.</span></h1>
              <hr />
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                voluptatem quia voluptas sit aspernatur aut odit aut fugit.
              </p>
            </div>
          </div>
          <div className="row form-section">
            <div id="contact-form" className="twelve columns">
              <form name="contactForm" id="contactForm" method="post" action>
                <fieldset>
                  <div className="row">
                    <div className="six columns mob-whole">
                      <label htmlFor="contactFname">First Name <span className="required">*</span></label>
                      <input name="contactFname" type="text" id="contactFname" placeholder="First Name" defaultValue />
                    </div>
                    <div className="six columns mob-whole">
                      <label htmlFor="contactLname">Last Name <span className="required">*</span></label>
                      <input name="contactLname" type="text" id="contactLname" placeholder="Last Name" defaultValue />
                    </div>
                  </div>
                  <div className="row">
                    <div className="six columns mob-whole">
                      <label htmlFor="contactEmail">Email <span className="required">*</span></label>
                      <input name="contactEmail" type="text" id="contactEmail" placeholder="Email" defaultValue />
                    </div>
                    <div className="six columns mob-whole">
                      <label htmlFor="contactSubject">Subject</label>
                      <input name="contactSubject" type="text" id="contactSubject" placeholder="Subject" defaultValue />
                    </div>
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                      <textarea name="contactMessage" id="contactMessage" placeholder="Your Message" rows={10} cols={50} defaultValue={""} />
                    </div>
                  </div>
                  <div>
                    <button className="submit full-width">Send Message</button>
                    <div id="image-loader">
                      <img src="images/loader.gif" alt />
                    </div>
                  </div>
                </fieldset>
              </form> {/* /contactForm */}
              {/* message box */}
              <div id="message-warning" />
              <div id="message-success">
                <i className="fa fa-check" />Your message was sent, thank you!<br />
              </div>
            </div> {/* /contact-form */}
          </div> {/* /form-section */}
        </section>
      </React.Fragment>
    );
  }
}