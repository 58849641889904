import React, { Component } from 'react';

export default class Hero extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="hero">
          <div className="row hero-content">
            <div className="twelve columns flex-container">
              <div id="hero-slider" className="flexslider">
                <ul className="slides">
                  {/* Slide */}
                  <li>
                    <div className="flex-caption">
                      <h1>Hello, we are Youth With a Vision.</h1>
                      <p><a className="button stroke smoothscroll" href="#about">More About Us</a></p>
                    </div>
                  </li>
                  {/* Slide */}
                  <li>
                    <div className="flex-caption">
                      <h1>We imagine, craft and manage your brand in this new digital world.</h1>
                      <p><a className="button stroke smoothscroll" href="#portfolio">See Our Works</a></p>
                    </div>
                  </li>
                  {/* Slide */}
                  <li>
                    <div className="flex-caption">
                      <h1>Take a look at some of <a className="smoothscroll" href="#portfolio" title>our works</a> or <a className="smoothscroll" href="#contact" title>contact us</a> to discuss how we can help you.</h1>
                      <p><a className="button stroke smoothscroll" href="#contact">Get In Touch</a></p>
                    </div>
                  </li>
                </ul>
              </div> {/* .flexslider */}
            </div> {/* .flex-container */}
          </div> {/* .hero-content */}
        </section>
      </React.Fragment>
    );
  }
}