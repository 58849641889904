import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer>
          <div className="row">
            <div className="six columns tab-whole footer-about">
              <h3>About YWAV</h3>
              <p>This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet.
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem
                nibh id elit.
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.</p>
            </div> {/* /footer-about */}
            <div className="six columns tab-whole right-cols">
              <div className="row">
                <div className="columns">
                  <h3 className="address">Contact Us</h3>
                  <p>
                    1600 Amphitheatre Parkway<br />
                    Mountain View, CA<br />
                    94043 US
                  </p>
                  <ul>
                    <li><a href="tel:6473438234">647.343.8234</a></li>
                    <li><a href="tel:1234567890">123.456.7890</a></li>
                    <li><a href="mailto:someone@puremedia.com">someone@puremedia.com</a></li>
                  </ul>
                </div> {/* /columns */}
                <div className="columns last">
                  <h3 className="contact">Follow Us</h3>
                  <ul>
                    <li><a href="#">Facebook</a></li>
                    <li><a href="#">Twitter</a></li>
                    <li><a href="#">GooglePlus</a></li>
                    <li><a href="#">Instagram</a></li>
                    <li><a href="#">Flickr</a></li>
                    <li><a href="#">Skype</a></li>
                  </ul>
                </div> {/* /columns */}
              </div> {/* /Row(nested) */}
            </div>
            <p className="copyright">© Copyright 2014 KCA YWAV. Design by <a href="http://www.styleshout.com/">Styleshout.</a></p>
            <div id="go-top">
              <a className="smoothscroll" title="Back to Top" href="#hero"><span>Top</span><i className="fa fa-long-arrow-up" /></a>
            </div>
          </div> {/* /row */}
        </footer>
      </React.Fragment>
    );
  }
}