import React, { Component } from 'react';

export default class Team extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="team">
          <div className="row team section-head">
            <div className="twelve columns">
              <h1>Our Rockstar Team<span>.</span></h1>
              <hr />
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                voluptatem quia voluptas sit aspernatur aut odit aut fugit.
              </p>
            </div>
          </div>
          <div className="row about-content">
            <div className="twelve columns">
              <div id="team-wrapper" className="bgrid-half mob-bgrid-whole group">
                <div className="bgrid member">
                  <div className="member-header">
                    <div className="member-pic">
                      <img src="images/team/member01-k.jpg" alt />
                    </div>
                    <div className="member-name">
                      <h3>Naruto Uzumaki</h3>
                      <span>Creative Director</span>
                    </div>
                  </div>
                  <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor,
                    nisi elit consequat ipsum, nec sagittis sem nibh id elit. </p>
                  <ul className="member-social">
                    <li><a href="#"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#"><i className="fa fa-google-plus" /></a></li>
                    <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                    <li><a href="#"><i className="fa fa-skype" /></a></li>
                  </ul>
                </div> {/* /member */}
                <div className="bgrid member">
                  <div className="member-header">
                    <div className="member-pic">
                      <img src="images/team/member02-k.jpg" alt />
                    </div>
                    <div className="member-name">
                      <h3>Sasuke Uchiha</h3>
                      <span>Lead Designer</span>
                    </div>
                  </div>
                  <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor,
                    nisi elit consequat ipsum, nec sagittis sem nibh id elit. </p>
                  <ul className="member-social">
                    <li><a href="#"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#"><i className="fa fa-google-plus" /></a></li>
                    <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                    <li><a href="#"><i className="fa fa-skype" /></a></li>
                  </ul>
                </div> {/* /member */}
                <div className="bgrid member first">
                  <div className="member-header">
                    <div className="member-pic">
                      <img src="images/team/member03-k.jpg" alt />
                    </div>
                    <div className="member-name">
                      <h3>Sakura Haruno</h3>
                      <span>Web Designer</span>
                    </div>
                  </div>
                  <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor,
                    nisi elit consequat ipsum, nec sagittis sem nibh id elit. </p>
                  <ul className="member-social">
                    <li><a href="#"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#"><i className="fa fa-google-plus" /></a></li>
                    <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                    <li><a href="#"><i className="fa fa-skype" /></a></li>
                  </ul>
                </div> {/* /member */}
                <div className="bgrid member">
                  <div className="member-header">
                    <div className="member-pic">
                      <img src="images/team/member04-k.jpg" alt />
                    </div>
                    <div className="member-name">
                      <h3>Shikamaru Nara</h3>
                      <span>Web Designer</span>
                    </div>
                  </div>
                  <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor,
                    nisi elit consequat ipsum, nec sagittis sem nibh id elit. </p>
                  <ul className="member-social">
                    <li><a href="#"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#"><i className="fa fa-google-plus" /></a></li>
                    <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                    <li><a href="#"><i className="fa fa-skype" /></a></li>
                  </ul>
                </div> {/* /member */}
              </div> {/* /twelve */}
            </div> {/* /team-wrapper */}
          </div> {/* /row */}
        </div> {/* /team */}
      </React.Fragment>
    );
  }
}