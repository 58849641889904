import React, { Component } from 'react';
import Details from './details';
import Team from './team';

export default class About extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="about">
          <Details/>
          <Team/>
        </section>
      </React.Fragment>
    );
  }
}