import React, { Component } from 'react';

export default class Portfolio extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="portfolio">
          <div className="row section-head">
            <div className="twelve columns">
              <h1>Our Latest Works<span>.</span></h1>
              <hr />
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                voluptatem quia voluptas sit aspernatur aut odit aut fugit.
              </p>
            </div>
          </div>
          <div className="row items">
            <div className="twelve columns">
              <div id="portfolio-wrapper" className="bgrid-fourth s-bgrid-third mob-bgrid-half group">
                <div className="bgrid item">
                  <div className="item-wrap">
                    <a href="portfolio.html">
                      <img src="images/portfolio/grizzly.jpg" alt="Grizzly" />
                      <div className="overlay" />
                      <div className="portfolio-item-meta">
                        <h5>Grizzly</h5>
                        <p>Photography</p>
                      </div>
                    </a>
                  </div>
                </div> {/* /item */}
                <div className="bgrid item">
                  <div className="item-wrap">
                    <a href="portfolio.html">
                      <img src="images/portfolio/hotel.jpg" alt="Hotel Sign" />
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>Hotel Sign</h5>
                          <p>Webdesign</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div> {/* /item */}
                <div className="bgrid stack item">
                  <div className="item-wrap">
                    <a href="portfolio.html">
                      <img src="images/portfolio/beetle.jpg" alt="Beetle" />
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>Beetle</h5>
                          <p>Branding</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div> {/* /item */}
                <div className="bgrid item">
                  <div className="item-wrap">
                    <a href="portfolio.html">
                      <img src="images/portfolio/banjo-player.jpg" alt="Banjo Player" />
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>Banjo Player</h5>
                          <p>Videography</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div> {/* /item */}
                <div className="bgrid item">
                  <div className="item-wrap">
                    <a href="portfolio.html">
                      <img src="images/portfolio/coffee.jpg" alt="Coffee Cup" />
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>Coffee Cup</h5>
                          <p>Photography</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div> {/* /item */}
                <div className="bgrid item">
                  <div className="item-wrap">
                    <a href="portfolio.html">
                      <img src="images/portfolio/farmerboy.jpg" alt="Farmerboy" />
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>Farmerboy</h5>
                          <p>Branding</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div> {/* /item */}
                <div className="bgrid item">
                  <div className="item-wrap">
                    <a href="portfolio.html">
                      <img src="images/portfolio/judah.jpg" alt="Judah" />
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>Judah</h5>
                          <p>Web Design</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div> {/* /item */}
                <div className="bgrid item">
                  <div className="item-wrap">
                    <a href="portfolio.html">
                      <img src="images/portfolio/embossed-paper.jpg" alt="Embossed Paper" />
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>Embossed Paper</h5>
                          <p>Branding</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>  {/* item end */}
              </div> {/* /portfolio-wrapper */}
            </div> {/* /twelve */}
          </div>  {/* /row */}
        </section>
      </React.Fragment>
    );
  }
}